<template>
  <div class="premios">
    <Voltar />
    <h1 class="titulo">Rede America Prêmios</h1>
    <!-- <p>09/05/2021 à 10/08/2021</p> -->

    <article class="premios__item" v-for="premio in state.premios" :key="premio.id">
      <img :src="premio.imagem" :alt="premio.titulo" />
      <h1>{{ premio.titulo }}</h1>
      <p>{{ premio.subtitulo }}</p>
    </article>
    <div>
      <div class="bt">
        <a @click="() => router.push({ name: 'Cadastro' })" v-if="!user">Participe</a>
        <a @click="() => router.push({ name: 'FormaPagto' })" v-if="user">Participe</a>
      </div>
      <div class="bt">
        <a @click="() => router.push({ name: 'Regulamento' })">Regulamento</a>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'
export default {
  setup () {
    const user = JSON.parse(window.localStorage.getItem('user'))
    const router = useRouter()
    const toast = useToast()

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      premios: {}
    })

    async function getPremios () {
      try {
        state.isLoading = true
        const { data, errors } = await services.premios.premios()

        if (!errors) {
          state.premios = data
          state.isLoading = false
          return
        }

        if (errors.status === 404) {
          toast.error('Prêmios não encontrado')
        }

        if (errors.status === 401) {
          toast.error('Prêmios Inválido')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar os Prêmios!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getPremios()

    return {
      router,
      state,
      user
    }
  },
  components: { Voltar }
}
</script>
